import React from "react"

export const Logo = () => (
  <svg width="152" height="60" viewBox="0 0 152 60" fill="none">
    <path
      d="M121.799 48.6113C121.149 48.2068 121.182 47.4297 121.898 46.2799C122.489 45.3302 123.29 44.5454 124.3 43.9257C126.818 42.442 129.222 40.921 131.51 39.3627C132.968 38.4676 134.346 37.5913 135.643 36.7339C136.99 35.9076 138.224 35.1501 139.346 34.4615C139.833 34.3483 140.226 34.3851 140.526 34.5718C141.176 34.9763 141.189 35.4007 140.566 35.8449C140.248 36.1326 139.833 36.4642 139.322 36.8396C138.892 37.1961 138.35 37.6215 137.696 38.1157C135.589 39.7173 134.196 40.8964 133.517 41.6528C135.695 41.2744 137.835 41.0115 139.938 40.8641C142.122 40.6979 144.082 40.6694 145.817 40.7786C147.553 40.8878 148.87 41.2225 149.77 41.7826C150.57 42.2804 150.767 42.8543 150.363 43.5041C150.021 44.054 149.397 44.4983 148.493 44.8369C147.62 45.1256 146.933 45.1144 146.433 44.8032C146.183 44.6476 146.03 44.4482 145.973 44.2049C145.855 44.0615 145.57 43.8498 145.12 43.5698C143.521 42.574 141.364 42.3064 138.65 42.767C135.835 43.1653 132.994 43.9974 130.125 45.2633C127.288 46.4793 125.021 47.6693 123.326 48.8332C122.758 48.9653 122.249 48.8913 121.799 48.6113Z"
      fill="#43BFF5"
    />
    <path
      d="M111.315 41.0251C109.591 40.8245 108.125 40.1961 106.916 39.1402C104.973 37.2498 104.192 34.6626 104.574 31.3787C104.957 28.0948 106.512 24.7395 109.241 21.3127C111.97 17.8858 115.855 14.8849 120.898 12.3099C121.786 11.8308 122.928 11.6725 124.323 11.835C126.13 12.0452 127.673 12.7241 128.954 13.8718C130.317 15.0289 130.744 16.3683 130.234 17.89C129.363 20.3681 127.828 22.4776 125.628 24.2186C123.51 25.9692 121.047 27.4715 118.238 28.7255C115.521 29.907 112.727 31.0379 109.855 32.1182C109.745 32.355 109.634 32.5917 109.523 32.8284C109.494 33.0747 109.466 33.321 109.437 33.5673C109.303 34.7167 109.553 35.7859 110.187 36.775C110.912 37.6915 111.973 38.231 113.369 38.3935C114.436 38.5177 115.358 38.4586 116.135 38.2162C117.506 37.8766 118.626 37.5493 119.495 37.2343C120.446 36.929 121.543 36.4327 122.788 35.7455C124.033 35.0583 125.737 34.0084 127.898 32.5958C128.877 32.0442 129.449 31.7779 129.614 31.797C130.106 31.8543 130.227 32.2429 129.977 32.9627C129.003 34.1807 127.399 35.4501 125.165 36.7711C123.013 38.1016 120.639 39.1982 118.042 40.0608C115.528 40.9331 113.285 41.2545 111.315 41.0251ZM110.981 28.8793C111.638 28.9557 113.069 28.4566 115.275 27.382C117.48 26.3073 119.576 25.0952 121.564 23.7456C125.018 21.2353 126.836 19.2003 127.018 17.6404C127.065 17.2299 126.985 16.8462 126.777 16.4891C126.579 16.0499 126.233 15.8017 125.741 15.7444C124.181 15.5628 121.339 17.1042 117.214 20.3685C114.383 22.5352 112.305 25.3721 110.981 28.8793Z"
      fill="#43BFF5"
    />
    <path
      d="M84.5119 35.6484C84.5119 41.8799 84.9309 46.4353 85.769 49.3147H76.2606C76.8622 47.2304 77.2383 44.77 77.3887 41.9336C77.711 35.079 77.8722 29.7607 77.8722 25.9789C77.8722 22.197 77.8614 19.6614 77.8399 18.3721C77.8185 17.0614 77.797 15.7506 77.7755 14.4399C77.6466 9.81997 77.4209 6.88687 77.0986 5.64057C76.7978 4.37279 76.5077 3.45955 76.2283 2.90087C75.8201 1.99837 75.1647 1.03142 74.2622 0H86.0268C85.4252 2.17028 85.0706 4.22237 84.9632 6.15628L84.7376 10.8299C84.5871 14.3754 84.5119 17.0721 84.5119 18.9201C84.5119 20.768 84.5227 22.0143 84.5442 22.659C84.5871 23.3036 84.6731 23.8301 84.802 24.2383C85.0599 25.0979 85.7045 25.5061 86.7359 25.4632C87.7888 25.4202 88.7343 25.2053 89.5723 24.8185C90.4104 24.4102 91.098 23.8945 91.6352 23.2714C92.7096 22.0681 93.2468 20.7895 93.2468 19.4358C93.2468 18.0821 93.0856 17.1903 92.7633 16.7606H102.078C101.82 17.2763 101.584 17.8027 101.369 18.3399C101.176 18.8556 100.961 19.3821 100.725 19.9193C99.1989 23.3144 96.7923 25.7747 93.5046 27.3004C93.7195 31.7484 95.1807 36.2071 97.8881 40.6766C100.445 44.8237 103.26 47.7031 106.333 49.3147H95.5352C93.3864 48.2833 91.6459 44.8237 90.3137 38.9361C89.8624 36.9377 89.5616 35.0145 89.4112 33.1666C89.2608 31.3186 89.0244 30.1153 88.7021 29.5566C88.3798 28.9764 87.7996 28.6863 86.9616 28.6863C85.3285 28.6863 84.5119 30.212 84.5119 33.2633V35.6484Z"
      fill="white"
    />
    <path
      d="M10.7977 3.86777C10.3464 5.52233 10.1208 7.50996 10.1208 9.83066C10.1208 12.1299 10.1638 13.6018 10.2497 14.2464C10.3572 14.8911 10.5183 15.4068 10.7332 15.7935C11.1415 16.4382 11.9365 16.7605 13.1184 16.7605H21.7565C21.7565 17.9638 21.4664 18.8878 20.8862 19.5324C19.8333 20.6928 18.5441 21.273 17.0184 21.273C16.2663 21.273 15.6002 21.1655 15.02 20.9506C13.9671 20.5209 13.1506 20.306 12.5704 20.306C11.9902 20.306 11.5175 20.4242 11.1522 20.6606C10.7869 20.8754 10.4968 21.1978 10.282 21.6275C9.89517 22.4011 9.70178 24.0879 9.70178 26.6879C9.70178 29.2879 9.77699 31.9524 9.92741 34.6814C10.0993 37.4104 10.5076 39.6988 11.1522 41.5468C11.7969 43.3733 12.7746 44.7485 14.0853 45.6725C15.3961 46.5964 17.2011 47.0584 19.5003 47.0584C19.5003 47.7246 19.049 48.5196 18.1465 49.4436C17.9102 49.7014 17.706 49.8733 17.5341 49.9593C13.7308 49.9593 10.8836 49.508 8.99268 48.6056C6.82241 47.5312 5.27528 45.6187 4.3513 42.8683C3.42732 40.0749 2.96533 35.9384 2.96533 30.459C2.96533 25.6672 2.8364 22.8416 2.57855 21.9821C2.32069 21.1011 2.08433 20.5209 1.86945 20.2415C1.61159 19.9192 0.988443 19.5217 0 19.049C1.33225 18.039 2.65375 16.1588 3.96452 13.4084L5.57611 10.024C6.71497 7.4455 7.68192 5.78019 8.47697 5.02811C9.29351 4.25455 10.0671 3.86777 10.7977 3.86777Z"
      fill="white"
    />
    <path
      d="M66.9138 34.5525C66.9138 41.5146 67.3328 46.4353 68.1708 49.3147H58.6624C59.2641 47.2304 59.6401 44.77 59.7906 41.9336C60.1129 35.079 60.274 29.7607 60.274 25.9789C60.274 22.197 60.2633 19.6614 60.2418 18.3721C60.2203 17.0614 60.1988 15.7506 60.1773 14.4399C60.0484 9.77699 59.8228 6.84389 59.5005 5.64057C59.1996 4.41576 58.9095 3.53476 58.6302 2.99756C58.3723 2.43888 58.0715 1.93391 57.7277 1.48266L56.6641 0H68.4287C67.827 2.17028 67.4725 4.2546 67.365 6.25298C67.2791 8.22986 67.2039 9.98113 67.1394 11.5068C67.0964 13.0324 67.0535 14.6225 67.0105 16.2771C66.9675 19.0275 66.946 22.3044 66.946 26.1078L66.9138 34.5525Z"
      fill="white"
    />
    <path
      d="M49.1535 32.1028L49.0568 38.2914C49.0568 42.1377 49.4114 44.7592 50.1205 46.1559C50.5502 46.9939 51.0552 47.6386 51.6354 48.0898L53.1503 49.3146H43.803L42.8038 45.5757C40.3757 48.4981 36.9161 49.9593 32.4252 49.9593C29.8681 49.9593 27.8053 49.0998 26.2367 47.3807C24.8614 45.8336 24.1738 44.0071 24.1738 41.9013C24.1738 39.7955 24.668 37.9583 25.6565 36.3897C26.6664 34.8211 27.9879 33.521 29.621 32.4896C32.8442 30.4912 36.8947 29.4491 41.7724 29.3631V29.0086C41.7724 23.787 40.9666 20.725 39.355 19.8225C38.7749 19.5002 38.0228 19.339 37.0988 19.339C36.1963 19.339 35.2938 19.6399 34.3913 20.2415C33.4888 20.8432 32.6723 21.6167 31.9417 22.5622C30.4805 24.4746 29.4384 26.7309 28.8152 29.3309C26.9028 28.192 25.9466 26.011 25.9466 22.7878C25.9466 21.4771 26.1185 20.0052 26.4623 18.3721C27.2788 18.3721 28.0524 18.2539 28.783 18.0175L31.2648 17.244C33.693 16.4919 36.0674 16.1158 38.3881 16.1158C40.7088 16.1158 42.5567 16.4597 43.9319 17.1473C45.3287 17.8349 46.4138 18.8556 47.1874 20.2093C48.4981 22.5085 49.1535 26.473 49.1535 32.1028ZM31.1359 42.546C31.1359 43.8782 31.4367 44.9096 32.0384 45.6402C32.6615 46.3708 33.4351 46.7361 34.3591 46.7361C35.2831 46.7361 36.1748 46.4997 37.0343 46.027C37.8938 45.5328 38.6674 44.8666 39.355 44.0286C40.9666 42.1162 41.7724 39.8707 41.7724 37.2922V32.5219C38.7641 32.8012 36.2823 33.8004 34.3269 35.5194C32.1996 37.3674 31.1359 39.7096 31.1359 42.546Z"
      fill="white"
    />
  </svg>
)
